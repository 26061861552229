import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import red_logo from '../assets/images/red_logo.png'

import bannercontact from '../assets/images/bannercontact.jpeg'




const Elements = (props) => (
    <Layout>
        <Helmet>
            <title>Contact Us</title>
            <meta name="description" content="Elements Page" />
        </Helmet>
       
       

        <div id="main" className="alt">

            <section id="one">
                <div className="inner">
                    <header className="major">
                        <h1>Contact Us</h1>
                    </header>
                    <span className="image main"><img src={bannercontact} alt="social words" /></span>
                    <h2 id="content">Looking for a website?</h2>

                    <p>GET IN TOUCH!</p>

                    {/* <p>Praesent ac adipiscing ullamcorper semper ut amet ac risus. Lorem sapien ut odio odio nunc. Ac adipiscing nibh porttitor erat risus justo adipiscing adipiscing amet placerat accumsan. Vis. Faucibus odio magna tempus adipiscing a non. In mi primis arcu ut non accumsan vivamus ac blandit adipiscing adipiscing arcu metus praesent turpis eu ac lacinia nunc ac commodo gravida adipiscing eget accumsan ac nunc adipiscing adipiscing.</p> */}
                    <div className="grid-wrapper">
                        <div className="col-6">
                            <h3>Email:</h3>
                            <p> info@redesignweb.ca</p>
                            <p> katiuscianovaes@gmail.com</p>
                        </div>
                        
                         <div className="col-6">
                            <h3>Chat:</h3>
                            <p><a href="https://t.me/katiuscianovaes" className="icon alt fa-telegram" target="_blank" rel="noopener noreferrer"><span className="label">Telegram</span></a></p>
                        </div>     
                       
                    </div>

                    <hr className="major" />

                </div>
                <blockquote style={{ textAlign: 'center' }}>"Creativity is inventing, experimenting, growing, taking risks, breaking rules, making mistakes, and having fun." -- Mary Lou Cook</blockquote>

                 <p><img src={red_logo} style={{ maxWidth: 200 , marginLeft:'45%'}} alt="red_logo"></img></p> 

                

                       
            </section>

        </div>

    </Layout>
)

export default Elements